<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    :style="drawer ? 'height:75px; left:56px' : 'height:75px; left:0px;'"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="drawer">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
    <h5 class="text-h5" style="color:gray">@{{current.role == 'ketua_tim_pokja' ? 'Ketua Tim Pokja' : current.role == 'kepala_sekolah' ? 'Kepala Sekolah' : current.role == 'koordinator_kesehatan' ? 'Koordinator Kesehatan' : current.role}}&nbsp;&nbsp;</h5>
    <!-- <h1>&nbsp;&nbsp;|&nbsp;&nbsp;</h1> -->
    <h1 class="text-h2">{{current.year}}</h1>
    <!-- notifikasi -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            :content="notifikasi.total"
            :value="notifikasi.total"
            overlap
            bordered
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>

        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(val, index) in notifikasi.slice(1)"
            :key="`item-${index}`"
            :style="val.tahun == (current.year-1) ? 'background-color:DarkRed;color:white' : ''"
          >
            <v-list-item :style="val.tahun == (current.year-1) ? 'background-color:DarkRed;color:white' : ''" link @click="val.tahun == (current.year-1) ? openDialogNotifikasi(val) : val.jenis == '-' ? $router.push({ path: val.link, query: { jenis: 1 } }) : $router.push({ path: val.link, query: { jenis: val.jenis } })">
              <v-list-item-content>
                <v-list-item-title style="font-size:14px" class="title" v-html="val.tahun == (current.year-1) ? val.title + ' ' + val.tahun : val.title" v-value="val.value"></v-list-item-title>
                <v-list-item-subtitle :style="val.tahun != undefined ? parseInt(val.tahun) == (parseInt(current.year)-1) ? 'background-color:DarkRed;color:white' : '' : ''" v-html="val.tahun == (current.year-1) ? val.subtitle + ' ' + val.tahun : val.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item v-if="val.link.includes('validasi')" :style="val.tahun != undefined ? parseInt(val.tahun) == (parseInt(current.year)-1) ? 'background-color:DarkRed;color:white' : '' : ''" link @click="$router.push({ path: val.link })">
              <v-list-item-content>
                <v-list-item-title style="font-size:14px" class="title" v-text="val.tahun != undefined ? val.title + ' ' + val.tahun : val.title" v-value="val.value"></v-list-item-title>
                <v-list-item-subtitle v-text="val.tahun != undefined ? val.subtitle + ' ' + val.tahun : val.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else :style="val.tahun != undefined ? parseInt(val.tahun) == (parseInt(current.year)-1) ? 'background-color:DarkRed;color:white' : '' : ''" link @click="$router.push({ path: val.link, query: { jenis: val.jenis } })">
              <v-list-item-content>
                <v-list-item-title style="font-size:14px" class="title" v-text="val.tahun != undefined ? val.title + ' ' + val.tahun : val.title" v-value="val.value"></v-list-item-title>
                <v-list-item-subtitle :style="val.tahun != undefined ? parseInt(val.tahun) == (parseInt(current.year)-1) ? 'background-color:DarkRed;color:white' : '' : ''" v-text="val.tahun != undefined ? val.subtitle + ' ' + val.tahun : val.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialogNotifikasi" max-width="800px" >
      <v-card>
        <v-card-title>
          <span class="headline">Mohon Selesaikan Dahulu Pekerjaan Anda Di Tahun Sebelumnya:</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formFormatSKP">
            <v-row align="center">
              <div class="mt-5">
                <label class="display-1">Mang Bagja {{dataNotifikasi.tahun}}: {{dataNotifikasi.title}} {{dataNotifikasi.tahun}}</label><br>
                <label class="display-1">Jumlah: {{dataNotifikasi.value}} Data</label><br>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <label class="display-1"><b>Silahkan logout dan login di tahun {{dataNotifikasi.tahun}}&nbsp;&nbsp;</b></label><br>
          <v-btn color="error" @click="logout()">Logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- avatar user menu -->
    <v-menu
      offset-y
      transition="slide-y-transition"
      bottom
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
        class="ml-2"
        text
        outlined
        v-bind="attrs"
        v-on="on"
        >
          <v-icon v-if="!avatar">mdi-account</v-icon>
          <v-avatar size="32">
            <v-img :src="avatar"></v-img>
          </v-avatar>
          <div class="d-flex flex-column">
            <div class="orange--text font-weight-bold" style="font-size:12px;">{{ isPLT ? 'PLT' : user.nama}}</div>
            <div class="grey--text font-weight-light" style="font-size:10px;">{{username}}</div>
          </div>
        </v-btn>
      </template>
      <v-list>
        <v-list-item  to="/ganti-peran">
          <v-list-item-title><v-icon>mdi-account-multiple</v-icon> Ganti Peran</v-list-item-title>
        </v-list-item>
        <v-list-item  to="/ganti-password">
          <v-list-item-title><v-icon>mdi-key</v-icon> Ganti Password</v-list-item-title>
        </v-list-item>
        <v-list-item  @click="logout()">
          <v-list-item-title><v-icon>mdi-exit-to-app</v-icon> {{$t("Logout")}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import store from '@/store'
  import _g from '@/global'
  import {local} from '@/store/local'
  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text primary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      dialogNotifikasi: false,
      dataNotifikasi: []
    }),

    computed: {
      ...mapState({
        drawer: state => state.settings.drawer,
        notifikasi: state => state.user.notifikasi,
        avatar: state=> state.user.avatar,
        username: state=> state.user.current.username,
        user: state=> state.user.dataPegawai,
        current: state=> state.user.current
      }),
      isPLT() {
        return _g.isPLT()
      },
    },

    methods: {
      ...mapMutations({
        setDrawer: 'settings/SET_DRAWER',
      }),
      logout(){
        /* eslint-disable-next-line no-console */
        store.commit('user/SET_DIRECT', false)

        let check = store.state.user
        if(check.data){
          if(check.data.direct){
            store.dispatch('routes/reset')
            window.localStorage.clear()
            window.location.href = 'https://gercep-asik.bandung.go.id/' // redirect to gercep-asik
          }
        }

        var check_login_ga = local.getLocal('login_via_ga')
        if(check_login_ga){
          store.dispatch('routes/reset')
          window.localStorage.clear()
          window.location.href = 'https://gercep-asik.bandung.go.id/' // redirect to gercep-asik
        }else{
          console.log('call logout')
          store.dispatch("user/logout")
        }
      },
      openDialogNotifikasi(val){
        this.dataNotifikasi = val
        // this.dialogNotifikasi = true
      }
    },
  }
</script>
